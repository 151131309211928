import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "../Layout/Layout";
import { lazy, Suspense } from "react";
import { SyncLoader } from "react-spinners";

const PopularQustions = lazy(() => import("../Pages/PopularQustions/PopularQustions"))
const Blog = lazy(() => import("../Pages/Blog/Blog"))
const Error = lazy(() => import('../Pages/Error/Error'))
const Services = lazy(() => import("../Pages/Services/Services"))
const TermsAndConditions = lazy(() => import("../Pages/TermsAndConditions/TermsAndConditions"))
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy/PrivacyPolicy"))
const AboutUs = lazy(() => import("../Pages/AboutUs/AboutUs"));
const Home = lazy(() => import("../Pages/Home/Home"))
const Contactus = lazy(() => import("../Pages/Contactus/Contactus"))
const Service = lazy(() => import("../Pages/Product/Product"))
const BlogDetails = lazy(() => import("../Pages/BlogDetails/BlogDetails"))
const Store = lazy(() => import("../Pages/Store/Store"))
const Request = lazy(() => import("../Pages/Request/Requests"))
const StoreData = lazy(() => import("../Pages/StoreDetails/StoreDetails"))

function App() {
  const router = createBrowserRouter([
    {
      path: "/", element: <Layout />, errorElement: <Error />, children: [
        { index: true, element: <Home /> },
        { path: "/Service/:id", element: <Service /> },
        { path: "/Blog/:id", element: <BlogDetails /> },
        { path: "/store/:id", element: <StoreData /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/contact-us", element: <Contactus /> },
        { path: "/Services", element: <Services /> },
        { path: "/common-Qustions", element: <PopularQustions /> },
        { path: "/blog", element: <Blog /> },
        { path: "/PrivacyPolicy", element: <PrivacyPolicy /> },
        { path: "/TermsAndConditions", element: <TermsAndConditions /> },
        { path: "/store", element: <Store /> },
        { path: "/request", element: <Request /> },
      ]
    }
  ])
  return (
    <>
      <Suspense fallback={<>
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <SyncLoader color="#b68d33" />
        </div>
      </>}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;
