import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import HeaderContact from '../Components/DComponents/HeaderContact/HeaderContact'
import Footer from '../Components/Common/Footer/Footer';

import style from "./Layout.module.css"

import whatsApp from "../Assets/Svg/whatsApp.svg"
import chatGbt from "../Assets/Svg/chatgbt.svg"

const { pattern, fixedImage, chatStyle } = style

const Layout = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile ? (
                <>
                    <div className={`${pattern}`}>
                        <Outlet />
                        <Footer />
                        <a href="https://openai.com/" target='_blank' rel="noopener noreferrer" >
                            <img
                                src={chatGbt}
                                className={`${fixedImage} ${chatStyle}`}
                                alt=""
                            />
                        </a>
                        <a href="https://wa.me/966533565303?text=" target='_blank' rel="noopener noreferrer" >
                            <img
                                src={whatsApp}
                                className={`${fixedImage}`}
                                alt=""
                            />
                        </a>
                    </div>
                </>
            ) : (
                <div className={`${pattern}`}>
                    <HeaderContact />
                    <Outlet />
                    <Footer />
                    <a href="https://openai.com/" target='_blank' rel="noopener noreferrer" >
                        <img
                            src={chatGbt}
                            className={`${fixedImage} ${chatStyle}`}
                            alt=""
                        />
                    </a>
                    <a href="https://wa.me/966533565303?text=" target='_blank' rel="noopener noreferrer" >
                        <img
                            src={whatsApp}
                            className={`${fixedImage}`}
                            alt=""
                        />
                    </a>
                </div>
            )}
        </>
    );

}

export default Layout
