import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// Styles
import { Col, Container, Row } from 'react-bootstrap'
import style from "./Footer.module.css"


// IMG + SVG
import logo from "../../../Assets/Img/Logo2.png"
import arrow from "../../../Assets/Svg/rightArrow.svg"
import leftArrow from "../../../Assets/Svg/leftArrow.svg"
import phone from "../../../Assets/Svg/phone.svg"
import location from "../../../Assets/Svg/location.svg"
import email from "../../../Assets/Svg/email.svg"
import facebook from "../../../Assets/Svg/facebook.svg"
import twiter from "../../../Assets/Svg/twiter.svg"
import instagram from "../../../Assets/Svg/instagram.svg"
import vision from "../../../Assets/Img/vision.png"
import { useTranslation } from 'react-i18next'

const { FooterStyle, logoStyle, arrowStyle, TextStyle, iconStyle, iconText, socialIcon, visionStyle } = style

const Footer = () => {
    const [state, setState] = useState(window.innerWidth <= 767);
    const { t } = useTranslation()

    useEffect(() => {
        const handleResize = () => {
            setState(window.innerWidth <= 767 ? true : false);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            {state ? <>
                <div className={`${FooterStyle} d-flex justify-content-center align-items-center py-4 flex-column`}>
                    <Container>
                        <Row className='g-3'>
                            <Col xs={12} lg={4}>
                                <div className='d-flex justify-content-center'>
                                    <img src={logo} className={`${logoStyle}`} alt="" />
                                </div>
                            </Col>
                            <Col xs={12} lg={4}>
                                <div className='d-flex justify-content-center mt-3 align-items-center flex-column gap-3'>
                                    <h2 className='text-white'> {t("contact-us")}</h2>
                                    <a className='text-decoration-none' target='_blank' rel="noopener noreferrer" href="https://www.google.com/maps/search/4192%20Ibn%20Al%20Amid%2C%206693%2C%20As%20Sulay%2C%20Riyadh%2014266%2C%20Saudi%20Arabia/@24.6675624847412,46.8486099243164,17z">
                                        <div className={`${iconText} d-flex align-items-center gap-3 flex-column align-items-center flex-lg-row flex-md-row`}>
                                            <img src={location} className={`${iconStyle}`} alt="" />
                                            <p className={`${iconText} text-white mb-0 text-center text-lg-start`}>{t("location")}</p>
                                        </div>
                                    </a>
                                    <div className={`${iconText} d-flex align-items-center`}>
                                        <a className='d-flex text-decoration-none gap-3 flex-column align-items-center flex-lg-row flex-md-row' href="mailto:Elsolbalzahaby@gmail.com">
                                            <img src={email} className={`${iconStyle}`} alt="" />
                                            <p className={`${iconText} text-white mb-0 text-center`}>Elsolbalzahaby@gmail.com</p>
                                        </a>
                                    </div>
                                    <div className={`${iconText} d-flex align-items-center gap-3 flex-column align-items-center flex-lg-row flex-md-row`}>
                                        <img src={phone} className={`${iconStyle}`} alt="" />
                                        <a href="tel:0533565303" className='d-flex align-items-center gap-2 text-decoration-none text-white'>0533565303</a>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                    </Container>
                    <Container className='mt-4'>
                        <Row className='align-items-center '>

                            <Col xs={12} lg={6}>
                                <div className={`${socialIcon} d-flex align-items-center justify-content-center mt-2 gap-3`}>
                                    <a href="https://www.facebook.com/profile.php?id=61557515717819" target="_blank" rel="norefrerr noreferrer"><img src={facebook} className={iconStyle} alt="" /></a>
                                    <a href="https://www.instagram.com/goldenstealll/" target="_blank" rel="norefrerr noreferrer"><img src={instagram} className={iconStyle} alt="" /></a>
                                    <a href="https://twitter.com/G0ldensteel" target="_blank" rel="norefrerr noreferrer"> <img src={twiter} className={iconStyle} alt="" /></a>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className={`${socialIcon} d-flex align-items-center justify-content-center mt-4 gap-3`}>
                                    <img src={vision} className={`${visionStyle}`} alt="" />
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col xs={12} lg={6}>
                                <div className='d-flex align-items-center flex-column gap-2 mb-3'>
                                    <p className='mb-0'><Link className='text-white text-decoration-none' to={"/PrivacyPolicy"}>
                                        {t("privacy policy")}</Link></p>
                                    <p className='mb-0'><Link className='text-white text-decoration-none' to={"/TermsAndConditions"}> {t("Terms and Conditions")} </Link></p>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <p className='text-white mb-0 text-center w-75'>{t("cobyRight")}</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>

            </> : <>
                <div className={`${FooterStyle} d-flex justify-content-center align-items-center py-4 flex-column`}>
                    <Container>
                        <Row className='g-3'>
                            <Col xs={12} lg={4}>
                                <div className='d-flex justify-content-center mt-3 align-items-center flex-column gap-3'>
                                    <h2 className='text-white'>{t("contact")}</h2>
                                    <a className='text-decoration-none' target='_blank' rel="noopener noreferrer" href="https://www.google.com/maps/search/4192%20Ibn%20Al%20Amid%2C%206693%2C%20As%20Sulay%2C%20Riyadh%2014266%2C%20Saudi%20Arabia/@24.6675624847412,46.8486099243164,17z">
                                        <div className={`${iconText} d-flex align-items-center gap-3 flex-column align-items-center flex-lg-row flex-md-row`}>
                                            <img src={location} className={`${iconStyle}`} alt="" />
                                            <p className={`${iconText} text-white mb-0 text-center text-lg-start`}>{t("location")}</p>
                                        </div>
                                    </a>
                                    <div className={`d-flex align-items-center gap-3 ${iconText}`}>
                                        <a className='d-flex text-decoration-none gap-3' href="mailto:Elsolbalzahaby@gmail.com">
                                            <img src={email} className={`${iconStyle}`} alt="" />
                                            <p className={`${iconText} text-white mb-0`}>Elsolbalzahaby@gmail.com</p>
                                        </a>
                                    </div>
                                    <div className={`d-flex align-items-center gap-3 ${iconText}`}>
                                        <img src={phone} className={`${iconStyle}`} alt="" />
                                        <a href="tel:0533565303" className={`d-flex align-items-center gap-2 text-decoration-none text-white ${iconText}`}>0533565303</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={4}>
                                <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                                    <h2 className='text-white'>{t("services")}</h2>
                                    {window.localStorage.getItem("i18nextLng") === "ar" ? <>
                                        <div className='d-flex gap-3 align-items-center text-end' >
                                            <p className={`${TextStyle} mb-0 text-white `}> {t("servicesOne")}</p>
                                            <img src={arrow} className={`${arrowStyle}`} alt="" />
                                        </div>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <p className={` mb-0 text-white text-start`}>{t("servicesTwo")}</p>
                                            <img src={arrow} className={`${arrowStyle}`} alt="" />
                                        </div>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <p className={`${TextStyle} mb-0 text-white `}>{t("servicesThree")}</p>
                                            <img src={arrow} className={`${arrowStyle}`} alt="" />
                                        </div>
                                    </> : <>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <img src={leftArrow} className={`${arrowStyle}`} alt="" />
                                            <p className={`${TextStyle} mb-0 text-white `}> {t("servicesOne")}</p>
                                        </div>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <img src={leftArrow} className={`${arrowStyle}`} alt="" />
                                            <p className={` mb-0 text-white text-start`}>{t("servicesTwo")}</p>
                                        </div>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <img src={leftArrow} className={`${arrowStyle}`} alt="" />
                                            <p className={`${TextStyle} mb-0 text-white `}>{t("servicesThree")}</p>
                                        </div>
                                    </>
                                    }
                                </div>
                            </Col>

                            <Col xs={12} lg={4}>
                                <div className='d-flex justify-content-center'>
                                    <img src={logo} className={`${logoStyle}`} alt="" />
                                </div>
                            </Col>

                        </Row>

                    </Container>
                    <Container className='mt-4'>
                        <Row className='align-items-center '>

                            <Col xs={12} lg={6}>
                                <div className={`${socialIcon} d-flex align-items-center justify-content-center mt-2 gap-3`}>
                                    <a href="https://www.facebook.com/profile.php?id=61557515717819" target="_blank" rel="norefrerr noreferrer"><img src={facebook} className={iconStyle} alt="" /></a>
                                    <a href="https://www.instagram.com/goldenstealll/" target="_blank" rel="norefrerr noreferrer"><img src={instagram} className={iconStyle} alt="" /></a>
                                    <a href="https://twitter.com/G0ldensteel" target="_blank" rel="norefrerr noreferrer"> <img src={twiter} className={iconStyle} alt="" /></a>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className={`${socialIcon} d-flex align-items-center justify-content-center mt-4 gap-3`}>
                                    <img src={vision} className={`${visionStyle}`} alt="" />
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col xs={12} lg={6}>
                                <div className='d-flex align-items-center flex-column gap-2 mb-3'>
                                    <p className='mb-0'><Link className='text-white text-decoration-none' to={"/PrivacyPolicy"}>
                                        {t("privacy policy")} </Link></p>
                                    <p className='mb-0'><Link className='text-white text-decoration-none' to={"/TermsAndConditions"}>{t("Terms and Conditions")}</Link></p>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <p className='text-white mb-0 text-center w-75'>{t("cobyRight")}</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </>}
        </>
    )
}

export default Footer
